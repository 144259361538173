.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
  }
  
  .cta-button {
    background-color: #ffa500;
    color: #000;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .cta-button.secondary {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
  }
  