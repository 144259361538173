body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.insurance-form-page {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.background-patterns {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}

.form-container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  margin-top: 60px;
  flex: 1 0 auto;
}

.form-card {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h1 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #202124;
}

p {
  color: #5f6368;
  margin-bottom: 24px;
  font-size: 14px;
}

.form-group {
  position: relative;
  margin-bottom: 16px;
}

.form-group input,
.form-group select {
  width: 100%;
  height: 56px;
  padding: 16px 14px 0;
  font-size: 16px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  background-color: transparent;
  box-sizing: border-box;
}

.form-group label {
  position: absolute;
  left: 14px;
  top: 20px;
  font-size: 16px;
  color: #5f6368;
  transition: all 0.15s ease-out;
  background-color: transparent;
  padding: 0 4px;
  pointer-events: none;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #1a73e8;
  box-shadow: 0 1px 2px 0 rgba(26,115,232,0.3), 0 1px 3px 1px rgba(26,115,232,0.15);
}

.form-group input:focus + label,
.form-group select:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group select:not(:placeholder-shown) + label,
.form-group label.filled {
  top: 8px;
  font-size: 12px;
  color: #1a73e8;
  background-color: white;
  padding: 0 4px;
}

.form-group input:focus + label,
.form-group select:focus + label {
  color: #1a73e8;
}

/* Date input specific styles */
.form-group input[type="date"] {
  color: #202124;
  font-family: 'Roboto', sans-serif;
}

.form-group input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  color: #5f6368;  /* Default gray color */
  font-family: 'Roboto', sans-serif;
}

/* Make text black when date is selected */
.form-group input[type="date"]:valid::-webkit-datetime-edit-fields-wrapper {
  color: #202124;
}

.form-group input[type="date"]::-webkit-datetime-edit {
  opacity: 1;
}

.form-group input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.form-content {
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}

.form-content.slightly-shifted .form-container {
  transform: translateX(-10%);
}

.cover-option-group {
  position: relative;
}

.cover-details {
  position: absolute;
  top: 0;
  right: -420px;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.cover-details p {
  margin: 0 0 10px;
}

.cover-details hr {
  border: none;
  border-top: 1px solid #e5e7eb;
  margin: 16px 0;
}

.cover-details p {
  color: black;
}

/* Placeholder text styles */
.form-group input::placeholder {
  color: #5f6368;
  font-size: 14px;
}

.cover-details h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.cover-details ul {
  list-style-type: disc;
  padding-left: 20px;
}

.cover-details ul li {
  font-size: 14px;
  margin-bottom: 5px;
}

.phone-group {
  display: flex;
  align-items: stretch;
}

.country-code {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: #f1f3f4;
  border: 1px solid #dadce0;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  color: #5f6368;
}

.phone-group input {
  flex-grow: 1;
  border-left: none;
  border-radius: 0 4px 4px 0;
  padding-left: 8px;
}

.phone-group label {
  left: 54px;
}

.payment-section {
  margin-top: 24px;
}

.payment-section h2 {
  font-size: 18px;
  margin-bottom: 16px;
  color: #202124;
}

.payment-methods {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.payment-methods label {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.payment-methods label.active {
  border-color: #1a73e8;
  background-color: #e8f0fe;
}

.payment-methods input[type="radio"] {
  display: none;
}

.payment-methods .icon {
  font-size: 20px;
  margin-right: 10px;
}

.insurance-form-page .summary-right{
  padding-left:20px;
  flex: none;
  position: absolute;
  left: calc(70%);
  top: auto;
  bottom: 200px;
  width: 300px;
}

.submit-btn {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-top: 16px;
}

.submit-btn:hover {
  background-color: #185abc;
}

.message {
  margin-top: 16px;
  padding: 10px;
  background-color: #e8f0fe;
  border: 1px solid #1a73e8;
  border-radius: 4px;
  color: #1a73e8;
  font-size: 14px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%235f6368' viewBox='0 0 12 12'%3E%3Cpath d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 14px center;
  padding-right: 30px;
}

.back-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.back-btn:hover {
  background-color: #e0e0e0;
}

.wave-pattern {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: rotate(180deg);
}

.geometric-pattern {
  position: absolute;
  top: 10%;
  right: 5%;
  width: 300px;
  height: 300px;
  opacity: 0.4;
}

.dots-pattern {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 200px;
  height: 200px;
  opacity: 0.5;
}

.family-addon-section {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e5e7eb;
}

.family-addon-label {
  position: relative;
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
}

.family-addon-label span {
  background: #fff;
  color: #555;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 8px;
  position: relative;
  z-index: 1;
}

.family-addon-label::before {
  content: "";
  display: block;
  border-top: 1px solid #ccc;
  width: 100%;
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 0;
}

.family-addon-subtitle {
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
  font-weight: bold;
}

.family-addon-details li {
  margin-bottom: 4px;
}