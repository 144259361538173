footer { 
  background-color: #000; 
  color: #fff; 
  padding: 3rem 0; 
} 
 
.footer-container { 
  display: flex; 
  justify-content: center; 
  align-items: flex-start; /* Changed from center to flex-start */
  flex-wrap: wrap; 
  gap: 2rem; 
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
} 
 
.footer-section { 
  flex: 0 1 300px;
  min-width: 250px;
  text-align: center;
} 

.footer-section h3 { 
  font-size: 1.5rem; 
  margin-bottom: 1rem; 
  color: #ff6600; 
} 
 
.footer-section p, 
.footer-section a { 
  font-size: 1rem; 
  line-height: 1.6; 
  color: #fff; 
  text-decoration: none; 
  margin: 0; /* Add this to remove any default margins */
} 
 
.footer-section a:hover { 
  text-decoration: underline; 
} 
 
.social-links { 
  display: flex; 
  justify-content: center;
  gap: 1rem;
  margin-top: 0; /* Add this to ensure no extra space at top */
} 
 
.social-links a { 
  color: #ff6600; 
  font-size: 1rem; 
  transition: color 0.3s ease; 
} 
 
.social-links a:hover { 
  color: #f0f0f0; 
} 
 
.footer-bottom { 
  text-align: center; 
  border-top: 1px solid #333; 
  margin-top: 2rem; 
  padding-top: 1rem; 
  font-size: 0.9rem; 
} 
 
@media (max-width: 768px) { 
  .footer-container { 
      flex-direction: column; 
      align-items: center;
  } 
  
  .footer-section {
      flex: 0 1 auto;
      width: 100%;
      max-width: 400px;
  }
}