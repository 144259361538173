body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.home-page {
  background-color: #000;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 1rem;
}

.navbar .cta-button {
  background-color: #ffa500;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
}

/* .navbar .cta-button:hover {
  background-color: #f0f0f0;
} */

.cta-button {
  background-color: #ffa500;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.hero {
  background-image: url('https://images.unsplash.com/photo-1622487560941-7f6bd5ed24b6?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
}

.hero-content {
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3rem;
  border-radius: 10px;
  color: #fff;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: #fff;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #fff;
}

.cta-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.cta-button {
  background-color: #ffa500;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.cta-button.primary {
  background-color: #fff;
  color: #000;
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

.faq-section {
  padding: 5rem 0;
  background-color: #fff;
  color: #000;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.faq-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 1.5rem 0;
  transition: all 0.3s ease;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
}

.faq-question:hover {
  color: #ff6600;
}

.faq-icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.faq-icon.open {
  transform: rotate(45deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  font-size: 1.1rem;
  line-height: 1.6;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 0 0 0 1.5rem;
}

.faq-answer.visible {
  max-height: 200px;
  padding: 1rem 0 0 1.5rem;
}

footer {
  background-color: #000;
  padding: 3rem 0;
  color: #fff;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  margin-bottom: 2rem;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #ff6600;
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  border-top: 1px solid #333;
  padding-top: 1rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .hero h1 {
      font-size: 2.5rem;
  }

  .hero p {
      font-size: 1rem;
  }

  .cta-buttons {
      flex-direction: column;
  }

 
}
