.cover-options-page {
    background-color: #fff;
    min-height: 100vh;
    padding: 6rem 0 3rem 0;
}

.cover-options-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
}

.page-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 1rem;
}

.page-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 2rem;
}

/* Progress Bar */
.progress-bar-container {
    background: #f9f9f9;
    padding: 1rem 2rem;
    border-bottom: 1px solid #e5e7eb;
    position: sticky;
    top: 71px; 
    z-index: 1000;
}

.progress-steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    color: #666;
    font-weight: 500;
}

.progress-step.active {
    color: #000;
    font-weight: 600;
}

.progress-line {
    position: relative;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
    overflow: hidden;
}

.progress-line-fill {
    background: #2d3748;
    height: 100%;
    width: 0;
    transition: width 0.3s ease;
}

.pricing-grid-container {
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
    margin: 1.5rem auto;
}

.pricing-card {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 0.75rem;
    overflow: hidden;
    transition: all 0.3s ease;
}

.pricing-card:hover {
    box-shadow: 0 12px 24px rgba(0,0,0,0.1);
    transform: translateY(-4px);
}

.card-content {
    padding: 1.25rem;
    padding-bottom: 2rem; 
}

.plan-type {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin-bottom: 0.75rem;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0.75rem 0 1rem 0; 
}

.features-list li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e7eb;
    text-align: left;
}

.features-list li:last-child {
    border-bottom: none;
    padding-bottom: 1rem;
}

.feature-title {
    display: block;
    color: #666;
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
}

.feature-value {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 0.9rem;
}

.select-button {
    display: block;
    width: 90%; 
    margin: 1rem auto 0 auto;
    background: #2d3748;
    color: #fff;
    text-decoration: none;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: background 0.2s ease;
    text-align: center;
    font-size: 0.9rem;
    padding: 0.75rem;
    border: none;
    cursor: pointer;
}

.select-button:hover {
    background: #4a5568;
}

.down-arrow {
    font-size: 2rem;
    color: #2d3748;
    cursor: pointer;
}
.down-arrow:hover {
    color: #4a5568;
}

/* Beneficiary Section */
.beneficiary-section {
    max-width: 1200px;
    margin: 4rem auto 0 auto;
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}

.beneficiary-left {
    flex: 2;
    min-width: 0;
}

.section-heading {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 1rem;
    color: #000;
}

.option-card {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
}

.option-card:hover {
    box-shadow: 0 8px 20px rgba(0,0,0,0.05);
}

.option-card-inner {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.option-card-inner input[type="radio"] {
    accent-color: #2d3748;
    margin-top: 4px;
}

.option-card-content h3, 
.option-card-content h4 {
    margin: 0 0 0.25rem 0;
    font-weight: 600;
    font-size: 1rem;
    color: #000;
}

.option-card-content p {
    margin: 0;
    font-size: 0.9rem;
    color: #444;
}

.summary-right {
    flex: 1;
    min-width: 220px;
}

.summary-box {
    background: #f9f9f9;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: left;
}

.summary-box h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}

/* Two-column label-value layout in summary */
.summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.summary-row .label {
    font-size: 0.9rem;
    color: #666;
    font-weight: 400;
}

.summary-row .value {
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
}

.summary-row .value strong {
    font-weight: 600;
    color: #000;
}

@media (max-width: 1024px) {
    .pricing-grid {
      grid-template-columns: 1fr;
      max-width: 350px;
      width: 100%;
      margin: 1.5rem auto;
    }

    .beneficiary-section {
      flex-direction: column;
    }

    .summary-right {
      width: 100%;
      margin-top: 2rem;
      text-align: center;
    }

    .summary-box {
      text-align: center;
    }

    .summary-row {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
}

@media (max-width: 768px) {
    .cover-options-page {
      padding: 7rem 0 3rem 0; 
    }

    .page-title {
      font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .cover-options-container {
      padding: 0 1rem;
    }

    .card-content {
      padding: 1rem;
      padding-bottom: 2rem;
    }
}
